import React, { useState, useCallback, useEffect } from 'react';
import './slider.css'

import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#101044',
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                outlined: {
                    backgroundColor: '#DBDBDB',
                    border: 0

                }
            }
        }
    },
    typography: {
        button: {
            textTransform: 'none'
        }
    }
});

const minDistance = 1;

function OwnSlider(props) {

const marks = [
    {
        value: props.min,
        label: props.min,
    },
    {
        value: props.max,
        label: props.max,
    },
    ];
  const [value1, setValue1] = React.useState([props.min, props.max]);

  const handleChange1 = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
    } else {
      setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)]);
    }
  };
    return (
        <div className="slider">
            <div className="row">
                <img className="" src={props.img}></img>
                <div>{props.text}</div>
            </div>
            <div className="column">
                <Box sx={{ width: 300 }}>
                    <Slider
                        aria-label="Custom marks"
                        defaultValue={value1}
                        value={value1}
                        onChange={handleChange1}
                        step={1}
                        valueLabelDisplay="auto"
                        marks={marks}
                        disableSwap
                        min={props.min}
                        max={props.max}
                    />
                </Box>
                <div className='row'>
                    <div>Entre</div>
                    <TextField size="small" className="text-field" value={value1[0]} onChange={ (e)=>{setValue1([e.target.value,value1[1]])}} />
                    <div>et</div>
                    <TextField size="small" className="text-field" value={value1[1]} onChange={ (e)=>{setValue1([value1[0],e.target.value])}}/>
                </div>
            </div>
        </div>
    )
}

export default OwnSlider;
