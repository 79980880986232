import React, { useState, useCallback } from 'react';
import './productDetails.css'

function ProductDetails(props) {

    return (
        <div className="product_details_div">
            
                {Object.keys(props.details).map((key, index) => {
                    return (
                        <div className="detail_name_row">
                            <div className="detail_name_tab">
                                <div className=" detail_name">{key}</div>
                            </div>
                            <div className="">
                                <text className=" detail_value">{props.details[key]}</text>
                            </div>
                        </div>

                    )
                })}
        </div>

    );
}


export default ProductDetails;
