import React, { useState, useCallback, useEffect } from 'react';
import './product.css'

function Product(props) {
    const [pictureToShow, setPictureToShow] = useState(props.imgs[0])
    const [pictureToShowIndex, setPictureToShowIndex] = useState(0)

    useEffect(() => {
        setPictureToShow(props.imgs[0])
        setPictureToShowIndex(0)
        
    })
    const onPictureClick = useCallback((picture, i) => {
        setPictureToShow(picture)
        setPictureToShowIndex(i)
    })

    return (
        <div className="product_div">
            <div className="name">{props.name}</div>
            <div className="name">{props.brand_name}</div>
            <img src={pictureToShow} className="main_picture" alt='main_picture' />
            <div className="picture_list">
                {
                    props.imgs.map((img, i) => {
                        if (i === pictureToShowIndex) {
                            return <img src={img} className="small_pictures selected_picture" alt='small_pictures' onClick={()=>onPictureClick(img, i)}/>
                        }
                        else {
                            return <img src={img} className="small_pictures" alt='small_pictures' onClick={()=>onPictureClick(img, i)}/>
                        }

                    })
                }
            </div>

        </div>

    );
}


export default Product;
