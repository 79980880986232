import './App.css';
import ProductPage from './components/productPage/ProductPage'
import SearchPage from './components/searchPage/searchPage';

import React, { useState, useCallback, useEffect } from 'react';

import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";


function App() {


    return (
      <Router>
      {/* A <Switch> looks through its children <Route>s and
        renders the first one that matches the current URL. */}
      <Routes>
        <Route path="/product" element={<ProductPage/>} />
        <Route path="/" element={<SearchPage/>} />
        <Route path="/search" element={<SearchPage/>} />
      </Routes>
  </Router>

    );
}

export default App;
