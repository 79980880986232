import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import SearchIcon from '@mui/icons-material/Search';
import './header.css';
import logo from './logo_benchr_1.svg';

import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

const drawerWidth = 240;

export default function Header(props) {
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar className='header'>
                    <a href='/'><img src={logo} className="logo_benchr" alt='logo' /></a>
                    <div className="frame_principale">
                        <div className="frame_button">
                        </div>
                        <div className="frame_button">
                        </div>
                        <div className="frame_button">
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                sx={{
                    backgroundColor: "pink",
                    color: "red",
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, color: "white", backgroundColor: "#101044", boxSizing: 'border-box' },
                }}


            >
                <Toolbar />
                <Box sx={{ overflow: 'auto' }}>
                    <List>
                        <ListItem key={"Recherche"} disablePadding>
                            <ListItemButton href='/'>
                                <ListItemIcon>
                                    <SearchIcon style={{ color: 'white' }} />
                                </ListItemIcon>
                                <ListItemText primary="Recherche" />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>

            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Toolbar />

                {props.children}

            </Box>

        </Box>
    );
}
