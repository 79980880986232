import './ProductPage.css';
import Product from '../product/product'
import ProductDetails from '../productDetails/productDetails';
import PriceList from '../priceList/priceList';

//temporaire test
import React, { useState, useCallback, useEffect } from 'react';


function ProductPage() {
  const [productData, setProductData] = useState([])

  useEffect(() => {
    // GET request using fetch inside useEffect React hook
  const queryParameters = new URLSearchParams(window.location.search)
  const internalId = queryParameters.get("internalId")
  const name = queryParameters.get("name")

    fetch('https://71o74yeafh.execute-api.eu-west-3.amazonaws.com/dev/getProduct?internalId='+internalId)
      .then(response => response.json())
      .then(
        data => {
          var result = []
          data["products"].forEach(product => {
            var product_json = {}
            var pictures = []
            if (product["aw_image_url"] && product["aw_image_url"] != "") {
              pictures.push(product["aw_image_url"].replace("w=200&h=200&", "w=360&h=360&"))
            }
            if (product["alternate_image_two"] && product["alternate_image_two"] != "") {
              pictures.push(product["alternate_image_two"])
            }
            if (product["alternate_image_two"] && product["alternate_image_two"] != "") {
              pictures.push(product["alternate_image_two"])
            }
            if (product["alternate_image_two"] && product["alternate_image_two"] != "") {
              pictures.push(product["alternate_image_two"])
            }
            product_json["photo"] = pictures

            product_json["name"] = product["product_name"]
            product_json["link"] = product["aw_deep_link"]
            product_json["brand_name"] = product["brand_name"]
            product_json["website"] = product["merchant_name"]
            product_json["price"] = product["search_price"]
            product_json["delivery_cost"] = product["delivery_cost"]

            //detail construction : 

            product_json["description"] = {
              "Nom de la marque": product["brand_name"],
              "Nom du produit": product["product_name"],
              "Catégorie": product["merchant_category"],
              "Description": product["description"],
            }

            result.push(product_json)
          })

          setProductData(result)

        }
      );

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, []);

  if (productData.length > 0) {
    return (
      <div>
        <div className='flex-container'>
          <div className='left'>
            <Product name={productData[0]["name"]} brand_name={productData[0]["brand_name"]} imgs={productData[0]["photo"]} />
          </div>
          <div className='right'>
          <ProductDetails details={productData[0]["description"]} />
          </div>
        </div>
        <PriceList data={productData}></PriceList>
      </div>

    );
  }

}

export default ProductPage;
